<template>
    <div class="h-min-screen flex flex-col items-stretch" @click="() => closeModal">
        <Banner class="mt-14" searchPlaceholder="Buscar por cpf da sessão">
            <h1 class="text-3xl font-bold text-center">Lista de sessões</h1>
            <p class="text-center my-4">Selecione a sessão que deseja visualizar os detalhes.</p>
        </Banner>
       
        <!-- Filtro -->
        <div class="mx-auto mt-4 z-10">
            <StatusFilter ref="statusFilter" class="my-4 mt-14" />
        </div>
        <div class="w-5/12 mx-auto flex justify-end mt-1" v-if="showAuditAll">
            <!-- <button
                class="border rounded-full font-medium text-white px-4 py-2"
                :style="{ backgroundColor: `${defineColorByClient}`, borderColor: `${defineColorByClient}` }"
                @click="auditAll()"
            >
                Auditar todos
            </button> -->

            <button class="flex items-center cursor-pointer" @click="auditAll()">
                <p class="font-medium mr-2" :style="{ color: `${defineColorByClient}` }">
                    Auditar em lote 
                </p>
                
            </button>
        </div>
        
        <!-- Calendário -->
        <!-- <div class="mx-auto mt-4 z-0">
            <CalendarInclude
                v-if="state.calendarOpened"
                @closeCalendar="state.calendarOpened = false"
            />
            <div
                class="w-full h-full overflow-hidden flex flex-col items-stretch"
                v-else
            >
                <div
                    class="
              relative
              h-18
              w-full
              bg-white
              border-b border-neutral-1
              pl-7
              pr-3
              flex
              items-center
              z-30
              flex-shrink-0
            "
                >
                    <img src="@/assets/icons/calendar.svg" />
                    <div class="flex-1 pl-7">
                        <p class="text-sm leading-5">Exames realizados</p>
                        <div
                            class="flex items-center cursor-pointer"
                            @click="state.calendarOpened = true"
                        >
                            <p class="font-bold text-lg leading-5">{{ formattedDate }}</p>
                            <img
                                class="transform transition-transform duration-200 w-3 mx-2"
                                :class="{ 'rotate-180': state.calendarOpened }"
                                src="@/assets/icons/arrow-down.svg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        
        <!-- Conteúdo -->
        <div class="w-5/12 mx-auto">
            <div v-if="state.loading" class="flex justify-center h-screen">
                <Loading :color="defineColorByClient" />
            </div>
            <ExamCard
                v-else
                v-for="exame in state.exames"
                :key="exame.id"
                :startDate="exame.examStartTime"
                :examId="exame.examId"
                :proctoringId="exame.id"
                :userCpf="exame.userCpf"
                :alertsCount="exame.alerts?.length"
                @examCardAction="handleClick(exame.id, exame.userCpf, new Date(exame.examStartTime))"
            />     
            <p v-if="state.exames.length === 0" class="text-center opacity-70">Nenhuma sessão encontrada!</p>   
        </div>
        <!-- Paginação -->
        <div class="flex justify-center px-32 py-6 flex-shrink-0">
            <button
                class="btn flex mx-4"
                @click.prevent="nextPage(state.page--)"
                :disabled="state.page == 1"
            >
                <!-- <img 
                    class="w-4 transform" 
                    src="@/assets/icons/arrow_left_page.svg"
                    :fill="defineColorByClient"
                /> -->
                <Icon icon="arrow_left_page" class="w-4 transform" :color="defineColorByClient" />
                <p class="text-center ml-1" :style="{ color: defineColorByClient }">Anterior</p>
            </button>
            <p class="text-center">{{ state.page }}</p>
            <button
                class="btn flex mx-4"
                @click.prevent="nextPage(state.page++)"
                :disabled="state.disableNext"
            >
                <p class="text-center mr-1" :style="{ color: defineColorByClient }">Próxima</p>
                <!-- <img 
                    class="w-4 transform rotate-180" 
                    src="@/assets/icons/arrow_left_page.svg"
                    :fill="defineColorByClient"
                /> -->
                <Icon icon="arrow_left_page" class="w-4 transform rotate-180" :color="defineColorByClient" />
            </button>
        </div>
        
    </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, watch, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import StatusFilter from "./StatusFilter.vue";

import RecordDTO from "@/dtos/RecordDTO";
import ExamAnswerDTO from "@/dtos/ExamAnswerDTO";
import ExamResponseDTO from "@/dtos/ExamResponseDTO";

import ExamCard from "@/components/ExamCard.vue";
import Banner from "@/components/Banner.vue";
import Loading from "@/components/Loading.vue";
import Icon from "@/components/Icon.vue";
import { format, parse } from "date-fns";
import { ptBR } from "date-fns/locale";
import ExameService from "@/services/ExameService";
import ProctoringResponseDTO from "@/dtos/ProctoringResponseDTO";
import vm from "@/viewModels/MainViewModel";
import { removeCpfMask } from "@/utils/stringFunctions";
import RecordService from "@/services/RecordService";
import { useAlert } from "@/hooks/alert";

enum ExamStatusEnum {
    CLOSED = "Exame executado",
    OPENED = "Exame agendado",
    RUNNING = "Exame em andamento",
}

interface ExamesState {
    loading: boolean;
    exames: ProctoringResponseDTO[];
    idExamesSelecionado?: number;
    currentExame: ProctoringResponseDTO | null;
    loadingDetail: boolean;
    examRecord: RecordDTO | null;
    examAnswer: ExamAnswerDTO | null;
    exam: ExamResponseDTO | null;
    selectedDate: Date;
    status?: number;
    onlyFraud?: boolean;
    order?: number;
    calendarOpened: boolean;
    filtersOpened: boolean;
    search?: string;
    endedPages: boolean;
    page: number;
    examesFinalizados: number;
    produtosPorPagina: number;
    produtosTotal: number;
    disableNext: boolean;
    pagination: any[];
}

const ExamesPage = defineComponent({
    components: {
        Banner,
        ExamCard,
        StatusFilter,
        Loading,
        Icon
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const alert = useAlert();


        const defineColorByClient = computed(() => {
            return vm.client?.hexColor ? `${vm.client?.hexColor}` : `#04986D`;
        });

        const state = reactive<ExamesState>({
            loading: false,
            exames: [],
            currentExame: null,
            idExamesSelecionado: undefined,
            loadingDetail: false,
            examRecord: null,
            examAnswer: null,
            exam: null,
            selectedDate: new Date(),
            status: undefined,
            order: undefined,
            onlyFraud: undefined,
            calendarOpened: false,
            filtersOpened: false,
            search: undefined,
            endedPages: false,
            page: 1,
            examesFinalizados: 0,
            produtosPorPagina: 5,
            produtosTotal: 0,
            disableNext: false,
            pagination: [
                { name: "currentPage", value: 0 },
                { name: "next", value: 0 },
                { name: "last", value: 0 },
                { name: "first", value: 0 },
                { name: "itensCount", value: 0 },
                { name: "itemsPerPage", value: 0 },
            ],
        });

        const formattedDate = computed(() => {
            return format(state.selectedDate, "dd / MMMM / yyyy", { locale: ptBR });
        });

        const query = (page: number) => {
            return {
                ...route.query,
                _page: page
            };
            // router.push({ path: 'register', query: { plan: 'private' }})
        };

        const nextPage = (page: number) => {
            query(page);
            loadExams();
            window.scrollTo(0,0);
        };

        const global = reactive({
            title: '',
            date: new Date(),
        });

        const handleClick = (id: string, title: string, date: Date) => {
            global.title = title;
            global.date = date;
            router.push({ name: 'exame', params: { id: id } });
        };

        // provide('global', global);

        const loadExams = async () => {
            state.loading = true;

            state.status = route.query["status"] ? parseInt(route.query["status"].toString()) : undefined;
            state.onlyFraud = route.query["fraud"] ? route.query["fraud"]=='true' : undefined;
            state.order = route.query["order"] ? parseInt(route.query["order"].toString()) : undefined;
            state.search = route.query["search"] ? removeCpfMask(route.query["search"].toString()) : undefined;

            // Calendário
            state.selectedDate = route.query["date"] ? new Date(route.query["date"].toString()) : new Date();
            const date = route.query["date"] ? new Date(route.query["date"].toString()) : undefined;
            const startHour = parse((route.query["startHour"] as string) || "00h", "HH'h'", new Date()).getHours();
            const finishHour = parse((route.query["finishHour"] as string) || "23h", "HH'h'", new Date()).getHours();

            // Filtros
            const [request] = await ExameService.GetExams({
                status: state.status,
                order: state.order,
                cpf: state.search,
                date: date,
                startHour: startHour,
                finishHour: finishHour,
                pageSize: state.produtosPorPagina,
                page: state.page,
                onlyFraud: state.onlyFraud
            });
            request
                .then(async (resp: any) => {
                    state.exames = resp.data;

                    const Xpagination = resp.headers["x-pagination"];
                    const startPagination = Xpagination.indexOf("?");
                    const paginationCorrected = Xpagination.slice(startPagination+1, Xpagination.length);
                    const paginationArray = paginationCorrected.split("&");

                    await paginationArray.map((item: any) => {
                        const equalItem = item.indexOf("=");

                        state.pagination.find((obj) => obj.name == item.slice(0, equalItem))!.value = item.slice(equalItem+1, item.length);
                    });   
                })
                .catch((error: any) => console.log(error))
                .finally(() => {
                    if (state.page >= state.pagination.find((obj) => obj.name == "last")!.value) {
                        state.disableNext = true;
                    } else {
                        state.disableNext = false;
                    }
                    state.loading = false;
                });
        };
        
        const auditAll = async () => {
            
            alert({
                icon: "alert",
                title: "Aviso",
                message: "Tem certeza que deseja liberar todos os exames pendentes?",
                actions: [
                    {
                        title: "Cancelar",
                        primary: true,
                    },
                    {
                        title: "Sim",
                        action: async () => {
                            var exames: any[] = []

                            // get all
                            const [requestProctorings] = await ExameService.GetExams({
                                status: state.status,
                                pageSize: 10000,
                                page: 1
                            });
                            requestProctorings
                                .then(async (resp: any) => {
                                    exames = resp.data;
                                    
                                    // audit all
                                    const [request] = await RecordService.AuditAll(exames.map((exam) => exam.id));
                                    request
                                        .then(() => {
                                            loadExams();
                                        })
                                        .catch((error: any) => console.log(error));
                                })
                                .catch((error: any) => console.log(error));

                            
                        },
                    },
                ],
            });
            
            // // Filtros
            // const [request] = await RecordService.AuditAll(state.exames.map((exam) => exam.id));
            // request
            //     .then(() => {
            //         loadExams();
            //     })
            //     .catch((error: any) => console.log(error));
        };

        const showAuditAll = computed(() => {
            return state.status == 4;
        });


        watch(() => route.query,() => {
            state.page = 1;
            loadExams();
        }, { deep: true });

        onMounted(() => loadExams());

        const statusFilter = ref();
        const closeModal = (event: any) => {
            if (!event.path[0].className.includes('dropdown') && statusFilter.value) {
                statusFilter.value.closeModal();
            }
        };

        return {
            ExamStatusEnum,
            state,
            nextPage,
            handleClick,
            formattedDate,
            closeModal,
            statusFilter,
            defineColorByClient,
            auditAll,
            showAuditAll
        };
    },
});

export default ExamesPage;
</script>

<style scoped>
.btn {
    opacity: 1;
}

.btn:disabled {
    opacity: 0.3;
    cursor: not-allowed;
}
</style>

