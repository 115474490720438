import { Get, Post } from "./BaseService";
import AuditoriaDTO from "@/dtos/AuditoriaDTO";
import AuditoriaImagemDTO from "@/dtos/AuditoriaImagemDTO";
import AuditResponseDTO from "@/dtos/AuditResponseDTO";
import ReportsQuery from '@/queryObjects/ReportsQuery';
import AlertsRankQuery from '@/queryObjects/AlertsRankQuery';
import AlertRankDTO from '@/dtos/AlertRankDTO';
import ReportsDTO from '@/dtos/ReportsDTO';

const RecordService = {
    Audit: (gravacaoId: string, data: AuditoriaDTO[]) => {
        return Post<AuditoriaDTO[], AuditResponseDTO>(`api/Proctoring/${gravacaoId}/audit`, data);
    },
    AuditImage: (gravacaoId: string, data: AuditoriaImagemDTO[]) => {
        return Post<AuditoriaImagemDTO[], AuditResponseDTO>(`api/Proctoring/${gravacaoId}/audit-image`, data);
    },
    
    AuditAll: (data: string[]) => {
        return Post<any, any>(`api/Proctoring/audit-all`, data);
    },

    AlertsRank: (query: AlertsRankQuery) => {
        return Get<AlertRankDTO[]>(`api/Proctoring/alerts-rank`, query);
    },

    Report: (query: ReportsQuery) => {
        return Get<ReportsDTO[]>(`api/Proctoring/report`, query);
    },
};

export default RecordService;